import { apiv1 } from "@/request/apiv1";
import qs from "qs";

export function GetClientFollowUpsMessagesRemindCount() {
  return new Promise((resolve, reject) => {
    apiv1.get(`/clients/follow_ups/messages/count/need_remind`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}


export function GetClientFollowUpsMessagesAll() {
  return new Promise((resolve, reject) => {
    apiv1.get(`/clients/follow_ups/messages/all`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}


export function GetClientFollowUpsMessagesList(params = { page: 0, size: 20 }) {
  return new Promise((resolve, reject) => {
    apiv1.get(`/clients/follow_ups/messages/page?${qs.stringify(params, {
      arrayFormat: "repeat"
    })}`)
      .then(res => {
        if (res.status === 200 || res.status === 206) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}



export function GetClientFollowUpsMessagesById(id = "") {
  return new Promise((resolve, reject) => {
    apiv1.get(`/clients/follow_ups/messages/${id}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function PostClientFollowUpsMessages(params = {}) {
  return new Promise((resolve, reject) => {
    apiv1.post(`/clients/follow_ups/messages`, params)
      .then(res => {
        if (res.status === 201) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function PutClientFollowUpsMessagesRead(id = "") {
  return new Promise((resolve, reject) => {
    apiv1.put(`/clients/follow_ups/messages/${id}/read`)
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function GetClientFollowUpsMessagesReadLogById(id = "") {
  return new Promise((resolve, reject) => {
    apiv1.get(`/clients/follow_ups/messages/${id}/read_logs`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}