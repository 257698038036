import { apiv1 } from "@/request/apiv1";

export function GetSystemVersion() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/global/setting/version`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function GetMessageRemindCount() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/messages/count/need_remind`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


export function PutSystemVersion(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/global/setting/version`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function GetGlobalSetting() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/global/setting`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function PutGlobalSetting(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/global/setting`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
export function GetGlobalSettingCheck() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/global/setting/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}