import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取客户邀约的列表
export function GetClientInvitationsAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/invitations/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取客户邀约的分页列表
export function GetClientInvitationsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/invitations/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建客户邀约记录
export function PostClientInvitations(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/clients/invitations`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定客户邀约的数据
export function PutClientInvitationsById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/invitations/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取客户邀约的具体信息
export function GetClientInvitationsById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/invitations/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 批量新增
export function MultiplePostClientInvitations(params = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        params.forEach(item => {
            multipleRequests.push(apiv1.post(`/clients/invitations`, item))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })

}

// // 批量删除
// export function MultipleDeleteClientInvitations(multipleSelectieIds = []) {
//     return new Promise((resolve, reject) => {
//         let multipleRequests = []
//         multipleSelectieIds.forEach(id => {
//             multipleRequests.push(apiv1.delete(`/clients/invitations/${id}`))
//         })
//         axios.all(multipleRequests)
//             .then(axios.spread(() => {
//                 resolve();
//             })).catch(err => {
//                 reject(err)
//             });
//     })
// }

// 禁用\启用指定用户
export function ToggleDisableById(id = "", bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/invitations/${id}/disable/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 动态批量设置启用\禁用
 * @param {Arrary} multipleSelectieIds 
 * @param {Boolean} bool 
 */
export function ToggleMultipleDisable(multipleSelectieIds = [], bool = false) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(id => {
            multipleRequests.push(apiv1.put(`/clients/invitations/${id}/disable/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

// 获取客户邀约状态的列表
export function GetClientInvitationsStatuses() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/invitations/statuses`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取邀约到访的列表
export function GetClientInvitationsVisitStatuses() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/invitations/statuses/visit`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
 export function GetDiasbleCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/invitations/${id}/can/disable`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
 export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/invitations/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}