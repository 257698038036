import { apiv1 } from "@/request/apiv1";
import qs from "qs";
// import axios from "axios";
// 获取所有的部门数据
export function GetDepartmentsAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/departments/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取所有的部门数据列表
export function GetDepartmentsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/departments/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取所有的部门数据树形列表
export function GetDepartmentsTreeList(id = "") {
    return new Promise((resolve, reject) => {
        let path = "/departments/tree"
        if(id !== ""){
            path = `/departments/tree/${id}`
        }
        apiv1.get(path)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 新增部门
export function PostDepartment(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/departments`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改部门
export function PutDepartmentById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/departments/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取部门详情
export function GetDepartmentById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/departments/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 删除部门
export function DeleteDepartmentById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.delete(`/departments/${id}`)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以更新
export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/departments/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以禁用/启用
export function GetDeleteCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/departments/${id}/can/delete`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}