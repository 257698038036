import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
let basePath = '/system'
const RouteModules = [
  {
    path: basePath,
    redirect: basePath + "/users/list",
    name: "System",
    component: BasicLayout,
    showChildren: true,
    groupTitle: "系统设置",
    iconSvg: "systemSetting",
    iconFont: "",
    onlyExtentdLayout: false,
    children: [
      {
        path: basePath + "/pages/manager",
        name: "PageManagerList",
        component: () => import("@/views/pagesManager/PageManagerList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "页面管理",
          keepAlive: true,
          iconSvg: "user",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/pages/manager/edit/:id?",
        name: "PageManagerEdit",
        component: () => import("@/views/pagesManager/PageManagerEdit.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "页面编辑",
          keepAlive: true,
          iconSvg: "user",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/pages/sections/type",
        name: "PageSectionsTypeList",
        component: () => import("@/views/pagesManager/pageSectionsType/PageSectionsTypeList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "页面类型",
          keepAlive: true,
          iconSvg: "user",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/users/list",
        name: "UsersList",
        component: () => import("@/views/users/UsersList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "用户管理",
          keepAlive: true,
          iconSvg: "user",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/departments/list",
        name: "DepartmentsList",
        component: () => import("@/views/departments/DepartmentList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "部门管理",
          keepAlive: true,
          iconSvg: "departmentManager",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/kpi/list",
        name: "KpiList",
        component: () => import("@/views/kpi/KpiList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "绩效管理",
          keepAlive: true,
          iconSvg: "kpi",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
        }
      },

      {
        path: basePath + "/sources/list",
        name: "ClientSourcesList",
        component: () => import("@/views/client/clientSources/ClientSourcesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "来源管理",
          keepAlive: true,
          iconSvg: "source",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/tags/tree",
        name: "ClientTagsTree",
        component: () => import("@/views/client/clientTags/ClientTagsTree"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "标签管理",
          keepAlive: true,
          iconSvg: "tags",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/types/list",
        name: "ClientTypesList",
        component: () => import("@/views/client/clientTypes/ClientTypesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "客户类型",
          keepAlive: true,
          iconSvg: "type",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/client/invitation/type/list",
        name: "ClientInvitationTypeList",
        component: () => import("@/views/client/clientInvitationType/ClientInvitationTypeList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "邀约类型",
          keepAlive: true,
          iconSvg: "type2",
          iconFont: "",
          roles: ['CLIENT_ADMIN']
        }
      },
      {
        path: basePath + "/stages/list",
        name: "ClientStagesList",
        component: () => import("@/views/client/clientStages/ClientStagesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "阶段管理",
          keepAlive: true,
          iconSvg: "stage",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/client/owner/list",
        name: "ClientOwnersList",
        component: () => import("@/views/client/clientOwners/ClientOwnersList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "负责人管理",
          keepAlive: true,
          iconSvg: "owner",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
        }
      },
      {
        path: basePath + "/followUps/statuses/list",
        name: "FollowUpsStatusesList",
        component: () => import("@/views/client/clientFollowUpsStatuses/ClientFollowUpsStatusesTree.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "跟进状态管理",
          keepAlive: true,
          iconSvg: "followups",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/merchants/types/list",
        name: "MerchantsTypesList",
        component: () => import("@/views/merchants/merchantsType/MerchanTypeList.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "招商类型管理",
          keepAlive: true,
          iconSvg: "merchants",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/questions/types/list",
        name: "QuestionsTypesList",
        component: () => import("@/views/questions/questionsTypes/QuestionsTypeList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "话术类型管理",
          keepAlive: true,
          iconSvg: "questions",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/payments/type/list",
        name: "PaymentsTypeList",
        component: () => import("@/views/paymentsTypes/PaymentsTypeList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "收款类型管理",
          keepAlive: true,
          iconSvg: "paymentType",
          iconFont: "",
          roles: []
        }
      },
      {
        path: basePath + "/files/list",
        name: "FilesManager",
        component: () => import("@/views/filesModule/FilesManager.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "静态文件管理",
          keepAlive: true,
          iconSvg: "fileManager",
          iconFont: "",
          roles: ['FILE_ADMIN']
        }
      }
    ]
  }
];

export default RouteModules;
