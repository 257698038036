import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的公司列表
export function GetClientsAll(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/all?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取客户的分页列表
export function GetClientsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的客户数据
export function GetClientById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新增客户
export function PostClient(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/clients`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 修改指定客户的数据
export function PutClientById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 设置签约客户
export function PutClientSignById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/${id}/sign`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 设置/取消星标
export function ToggleStartClientById(id = "", bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/${id}/star/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 设置客户阶段 
export function PutClientStage(id = "", stageId = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/${id}/stage`, {
            stageId
        })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 设置客户负责人
export function PutClientOwner(id = "", ownerId = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/${id}/owner`, {
            ownerId
        })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 禁用\启用指定用户
export function ToggleDisableClientById(id = "", bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/${id}/disable/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 设置客户标签
export function PutClientTagsById(id = "", tagIds = []) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/${id}/tag`, {
            tagIds: tagIds
        })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 动态批量设置启用\禁用
 * @param {Arrary} multipleSelectieIds 
 * @param {Boolean} bool 
 */
export function ToggleMultipleDisableClients(multipleSelectieIds = [], bool = false) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(item => {
            multipleRequests.push(apiv1.put(`/clients/${item}/disable/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

/**
 * 批量设置负责人
 * @param {Arrary} multipleSelectieIds 
 * @param {Boolean} bool 
 */
export function MultipleOwnerClients(multipleSelectieIds = [], ownerId = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/owner`, {
            clientIds: multipleSelectieIds,
            ownerId: ownerId
        })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
    // return new Promise((resolve, reject) => {
    //     let multipleRequests = []
    //     multipleSelectieIds.forEach(id => {
    //         multipleRequests.push(apiv1.put(`/clients/${id}/owner`, { ownerId }))
    //     })
    //     axios.all(multipleRequests)
    //         .then(axios.spread(() => {
    //             resolve();
    //         })).catch(err => {
    //             reject(err)
    //         });
    // })
    
}

/**
 * 动态批量设置客户星标/取消星标
 * @param {Arrary} multipleSelectieIds 
 * @param {Boolean} bool 
 */
export function ToggleMultipleStartClients(multipleSelectieIds = [], bool = false) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(item => {
            multipleRequests.push(apiv1.put(`/clients/${item}/star/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}



// 获取客户阶段统计列表,筛选字段和客户列表保持一致
export function GetClientStatisticsStages(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/statistics/stages?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查客户是否为重复客服
export function GetClientRepeatedCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}/repeated`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以禁用/启用
export function GetDisableCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}/can/disable`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以签约
export function GetSignCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}/can/sign`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以修改
export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以修改负责人
export function GetUpdateOwnerCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}/can/update/owner`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以修改阶段
export function GetUpdateStageCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}/can/update/stage`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查该用户是否为认领的
export function GetCheckClientClaimed(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}/claimed`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

