import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的用户列表
export function GetUsersAll(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/all?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取用户的分页列表
export function GetUsersList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取用户的角色
export function GetUsersRoles() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/roles`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的用户信息
export function GetUserById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建用户
export function PostUsers(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/users`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定用户
export function PutUserById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/users/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 管理员修改指定用户密码
export function PutUserPasswordById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/users/${id}/password`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 当前用户修改自己的密码
export function PutCurrentUserPassword(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/users/password`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 动态启用\禁用用户
 * @param {String,Number} id 
 * @param {Boolean} bool 
 */
export function ToggleDisableUsersById(id = "", bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/users/${id}/disable/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 激活指定的用户
export function ActivateUser(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/users/activate`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 激活指定的用户
export function PutCurentUserPhoneEdit(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/users/phone_no`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function PutEntWxUserUpdate(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/users/update/ent_wx_user_id`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检测用户名是否重复
export function DuplicateUserName(username = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/${username}/duplicate`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取当前用户的信息
export function GetCurrentUserDetail() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/self`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 批量禁用/启用
export function MultipleToggleDisableUsers(multipleSelectieIds = [], bool = null) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(item => {
            multipleRequests.push(apiv1.put(`/users/${item}/disable/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
export function GetDiasbleCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/${id}/can/disable`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
export function GetWxEntUserIdUpdateCheck(ids = []) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/can/update/ent_wx_user_id?ids=${ids}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
export function GetUpdatePasswordCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/users/${id}/can/update/password`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}