import { apiv1 } from "@/request/apiv1";
import qs from "qs";

// 获取所有的公共客户列表
export function GetClientPublicAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/public/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取公共客户的分页列表
export function GetClientPublicList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/public/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定公共客户的数据
export function PutClientPublicById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/public/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的公共客户数据
export function GetClientPublicById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 认领客户
export function PutClientPublicClaimByIds(ids = []) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/clients/public/claim?ids=${ids}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以修改
export function GetClaimCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/public/${id}/can/claim`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取客户认领记录的分页列表
export function GetClientCliamMessageList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/claim_logs?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 客户认领记录详情
export function GetClientCliamById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/claim_logs/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}