import { apiv1 } from "@/request/apiv1";
import qs from "qs";

// 获取客户阶段的列表
export function GetClientOwnersAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/owners/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取客户阶段的分页列表
export function GetClientOwnersList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/owners/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取客户的具体信息
export function GetClientOwnersById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/clients/owners/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

